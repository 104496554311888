<template>
  <div>
    <v-app id="app">
        <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
  export default {
    name: 'App'
  }
</script>