export default {
    tarefas: [
        { id: 1, titulo: "Ir ao médico", concluido: false },
        { id: 2, titulo: "Comprar ração", concluido: false },
    ],
    loading: [],
    article_loading: [],
    localStorage: [],
    unitsAll: [],
    unitSlug: [],
    categoriesSlug: [],
    subcategorieSlug: [],
    article: []
}